html {
    height: 100%;
    width: 100%;
}

body {
    font-family: 'Quicksand', sans-serif;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #34495e;
}

header {
    min-height: 100vh;
    background: #34495e;
    /* margin: 10em 10em;
    padding: 10em 10em; */
}

.termsOfService{
    padding: 10vh 10em;
    border-bottom: 1px solid #e8e8e8;
}

.privacyPolicy, .about {
    padding: 10vh 20em;
    border-bottom: 1px solid #e8e8e8;
}

@media (max-width: 992px) {
    .termsOfService, .privacyPolicy, .about {
        padding: 10vh 0;

   }
}

.centerHeader {
    display: flex;
    align-items:center;
    justify-content: center;
    padding: 10vh 2.5em;
}

.centerContent {
    background: #fff;
    display: flex;
    align-items:center;
    justify-content: center;
    padding-top: 24px;
}

.cardSection {
    /* display: flex; */
    align-items:center;
    justify-content: center;
    padding: 10em 5em;
    margin: 0 auto;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;

}

@media (max-width: 992px) {
    .cardSection {
        padding: 5em 0em;
   }
}


.ant-card {
    margin: 2em auto;
}

.ant-card-meta-description {
    color: #4A4A4A;
}

.ant-card-bordered {
    /* border: 1px solid; */
    box-shadow: 0px 1px 5px #96CDFF;
}

.ant-card-hoverable:hover {
    box-shadow: 0 2px 8px #2391FF;
}

img {
    display: flex;
    justify-content: center;
    margin: 0vh auto;
}

h1, h2, h3, h4, h5 {
    font-family: 'Quicksand', sans-serif;
}

h1 {
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: 300;
    font-size: 72px;
    color: #2391FF;
    margin: 0 auto;
}

h2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 1.5em;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(250, 250, 250, 1.0);
}

h3 {
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    font-size: 25px;
    color: rgba(250, 250, 250, 1.0);
    margin: 0 0 1.5em 0;
    white-space: nowrap
}

h4 {
    font-weight: 600;
    font-size: 12px;
    color: #34495e;
    margin: 0 auto;
  }

h5 {
    font-weight: 300;
    font-size: 14px;
    color: rgba(250, 250, 250, 1.0);
    margin: 0 auto;
}

@media (max-width: 992px) {
    h1 {
        font-size: 62px !important;
   }
}

.ant-tooltip {
    font-family: 'Quicksand', sans-serif;
}

.ant-menu-horizontal {
    border-bottom: 0;
}

.ant-carousel .slick-slide {
    text-align: center;
    height: 375px;
    background: #fff;
    line-height: 5em;
    overflow: hidden;
}

.ant-carousel .slick-dots li button {
    background: #2391ff;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #2391ff;
}

.ant-carousel .slick-slide img {
    width: auto;
    height: auto;
    max-height: 150px;
    max-width: 300px;
    display: block;
    margin: 2.5em auto 2.5em auto;
}

.ant-carousel .slick-slide h1 {
    color: rgb(5, 5, 5);
    font-weight: 700;
    font-size: 32px;
}

.ant-carousel .slick-slide h2 {
    color: rgb(75, 75, 75);
    font-weight: 400;
    font-size: 18px;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
    border-bottom: 1px solid #e8e8e8;
}

@media (max-width: 992px) {
    .ant-carousel .slick-slide h1 {
        font-size: 32px !important;
   }
}
